import React from "react";
import type {} from "@mui/lab/themeAugmentation"; // Used to theme lab components
import type {} from "@mui/x-data-grid/themeAugmentation"; // Used to style data grid
import type {} from "@mui/x-data-grid-pro/themeAugmentation"; // Used to style data grid pro
import { Theme, alpha, createTheme } from "@mui/material/styles";
import { CardProps } from "@mui/material/Card";
import { InputLabelProps } from "@mui/material/InputLabel";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export const backGround = "#F2F5F8";
export const darkBlack = "#090515";
export const black = "#101727"; // "#1C2530" "#09101D"
//const background = "#0D0D0E"; // used in user avatar, not sure if we can group this with black
const greyFifty = "#F8FAFC";
export const greyOne = "#F2F5F8";
export const greyOneAndHalf = "#EBEFF4";
export const greyTwo = "#E3E8EF"; // #F5F8FC
export const greyThree = "#CDD5E0";
const greyFour = "#96A3B6";
export const greyFive = "#677489";
const greySix = "#495567";
export const greySeven = "#354153";
const greyEight = "#202939";
export const greyNine = "#101729";

export const purpleLighter = "#F3F3FD";
export const purpleLight = "#C9D2FA";
export const purple = "#4F46E5";
export const purpleDark = "#34349D";
export const purpleDarker = "#110754";

const pink = "#f680d1";
const pinkLighter = "#fef1f9";

// gradient from near white purple -> purple -> black
export const heatmapPurple100 = "#FCFCFF";
export const heatmapPurple200 = "#E6E5FC";
export const heatmapPurple300 = "#D1CFF9";
export const heatmapPurple400 = "#BBB8F5";
export const heatmapPurple500 = "#A6A1F2";
export const heatmapPurple600 = "#908AEF";
export const heatmapPurple700 = "#7A74EC";
export const heatmapPurple800 = "#655DE8";
export const heatmapPurple900 = purple; // midpoint
export const heatmapPurple1000 = "#453DC8";
export const heatmapPurple1100 = "#3B35AC";
export const heatmapPurple1200 = "#312C8F";
export const heatmapPurple1300 = "#282373";
export const heatmapPurple1400 = "#1E1A56";
export const heatmapPurple1500 = "#141239";
export const heatmapPurple1600 = "#0A091D";
export const heatmapPurple1700 = "#000000";

// heatmap gradients
export const heatmapGradient1 = `linear-gradient(to right, ${heatmapPurple100}, ${heatmapPurple500})`;
export const heatmapGradient2 = `linear-gradient(to right, ${heatmapPurple500}, ${heatmapPurple900})`;
export const heatmapGradient3 = `linear-gradient(to right, ${heatmapPurple900}, ${heatmapPurple1300})`;
export const heatmapGradient4 = `linear-gradient(to right, ${heatmapPurple1300}, ${heatmapPurple1700})`;

const redLighter = "#FCF2F2";
export const redLight = "#F0A9A7";
export const red = "#DD524C";
const redDark = "#AA2E26";
const redDarker = "#752522";
const greenLighter = "#F0FCFC";
export const greenLight = "#79DBD4";
export const visitorsTeal = "#6FC8CA";
export const dwelltimeblue = "#38A6DA";
export const green = "#0B9689";
const greenDark = "#056360";
const greenDarker = "#013A38";
const yellowLighter = "#FEFBEC";
export const yellowLight = "#F9E696";
export const yellow = "#E4992E";
const yellowDark = "#A85823";
const yellowDarker = "#70381A";
const progressBarGradient = `linear-gradient(270deg, ${purple} 0%, ${purpleLight} 100%)`;
export const lightShadow = "0px 2px 8px rgb(16 24 40 / 3%)";
const mediumShadow =
  "0px 1px 3px rgba(150, 163, 182, 0.1), 0px 1px 2px rgba(32, 41, 57, 0.06)";
export const dropDownBoxShadow =
  "0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%)";
export const tableBlurTextShadow = "0 0 6px rgba(0, 0, 0, 0.6)";

export const dataGridHeaderStyles = {
  fontWeight: 400,
  textTransform: "uppercase",
  fontSize: "0.75rem",
  lineHeight: "1rem",
  color: greySix,
} as const;

export const barColors = [
  purple,
  yellow,
  green,
  red,
  greyFive,
  yellowLight,
  greenLight,
  redLight,
  greyThree,
];

export const visitorsBarColors = [
  green,
  yellow,
  red,
  purple,
  greyFive,
  yellowLight,
  greenLight,
  redLight,
  greyThree,
];

export const dwellBarColors = [
  dwelltimeblue,
  red,
  purple,
  green,
  yellow,
  greyFive,
  yellowLight,
  greenLight,
  redLight,
  greyThree,
];

const SPACING = 4;
const cardPaddingX = SPACING * 6;

export const occuDropdownZIndex = 2000;
export const MAIN_CONTENT_HORIZONTAL_PADDING = { xs: 0, sm: 8 };
export const MAIN_CONTENT_PADDING_TOP = { xs: 5, sm: 8 };

export const extraSmallButtonStyles = {
  fontSize: "0.75rem",
  lineHeight: "16px",
  paddingLeft: 2,
  paddingRight: 2,
  paddingTop: 1,
  paddingBottom: 1,
  "& .MuiButton-startIcon": {
    mr: 1,
    "& svg": { height: 14, width: 14 },
  },
};

export const leftPanelToggleIconId = "left-panel-toggle-icon";

// Native date input picker is an icon on the right.
// These styles allow the user to click anywhere on the input to open the picker.
export const calendarPickerIndicatorOverrides = {
  "& input::-webkit-calendar-picker-indicator": {
    background: "transparent",
    bottom: "0",
    color: "transparent",
    cursor: "pointer",
    height: "auto",
    left: "0",
    position: "absolute",
    right: "0",
    top: "0",
    width: "auto",
  },
};

// Overrides Safari centering text on native inputs
export const nativePickerOverrides = {
  "& input::-webkit-date-and-time-value": { textAlign: "start" },
  ...calendarPickerIndicatorOverrides,
};

export const loadingOpacity = 0.5;

const body2 = {
  fontSize: "0.875rem",
  lineHeight: "1.25rem",
};
const body1 = { fontSize: "1rem", lineHeight: "1.5rem" };

declare module "@mui/material/styles" {
  interface BreakpointOverrides {
    xs: true;
    sm: true;
    md: true;
    lg: true;
    xl: true;
    xxl: true; // adds extra large breakpoint
  }
}

declare module "@mui/material/Button" {
  interface ButtonPropsVariantOverrides {
    underline: true;
    desktopTable: true;
    pageHeader: true;
  }
}

declare module "@mui/material/styles" {
  interface TypographyVariants {
    label: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    label?: React.CSSProperties;
  }
}

// Update the Typography's variant prop options
declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    label: true;
  }
}

declare module "@mui/material/InputLabel" {
  interface InputLabelPropsVariantOverrides {
    dialog: true;
  }
}

// Intermediate theme to gain access to breakpoints within theme. https://github.com/mui/material-ui/issues/28330#issuecomment-919852856
let theme = createTheme();
theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
      xxl: 1800,
    },
  },
  spacing: SPACING,
  components: {
    MuiDataGrid: {
      styleOverrides: {
        root: {
          backgroundColor: "white",
          borderColor: greyTwo,
        },
        cell: {
          "&:first-of-type": {
            paddingLeft: cardPaddingX,
          },
          "&:last-child": {
            paddingRight: cardPaddingX, // TODO: not working
          },
        },
        columnHeaderTitle: {
          ...dataGridHeaderStyles,
        },
        columnHeader: {
          "&:first-of-type": {
            paddingLeft: cardPaddingX,
          },
          "&:last-child": {
            paddingRight: cardPaddingX,
          },
        },
        footerContainer: {
          "& .MuiInputBase-root.MuiInputBase-colorPrimary": {
            marginRight: 0,
            marginLeft: 0,
            paddingLeft: 0,
          },
        },
      },
    },
    MuiTreeItem: {
      styleOverrides: {
        root: {
          "& .MuiTreeItem-content:hover": {
            backgroundColor: purpleLighter,
            "& p": {
              color: purple,
            },
          },
          // Remove highlight when expansion icon is clicked
          "& .MuiTreeItem-content.Mui-focused": {
            backgroundColor: "transparent",
            "& p": {
              color: black,
            },
            "&:hover": {
              backgroundColor: purpleLighter,
              "& p": {
                color: purple,
              },
            },
          },
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: darkBlack,
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          height: "100%",
          width: "100%",
          "& #root": {
            height: "100%",
            width: "100%",
          },
        },
        html: {
          height: "100%",
        },
        "& .highcharts-reset-zoom": {
          "& title, text": {
            textTransform: "capitalize",
            fontFamily:
              [
                "Nunito",
                "-apple-system",
                "BlinkMacSystemFont",
                '"Segoe UI"',
                "Roboto",
                '"Helvetica Neue"',
                "Arial",
                "sans-serif",
                '"Apple Color Emoji"',
                '"Segoe UI Emoji"',
                '"Segoe UI Symbol"',
              ].join(",") + "!important",
          },
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        root: {
          "&:hover": {
            "& .Mui-checked": {
              backgroundColor: "transparent !important",
            },
          },
          "& .MuiSwitch-switchBase:hover": {
            backgroundColor: "transparent",
          },
          padding: SPACING * 2,
          "& .MuiSwitch-track": {
            borderRadius: 11,
          },
          "& .MuiSwitch-thumb": {
            boxShadow: "none",
            width: 16,
            height: 16,
            margin: SPACING / 2,
          },
        },
      },
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
      variants: [
        {
          props: { variant: "underline" },
          style: {
            boxShadow: "none",
            textDecoration: "underline",
            color: black,
            marginTop: 0,
            padding: 0,
            minWidth: "unset",
            "&:hover": {
              backgroundColor: "transparent",
            },
            fontSize: 14,
            fontWeight: "normal",
          },
        },
        {
          props: { variant: "desktopTable" },
          style: {
            fontSize: "0.875rem",
            fontWeight: "normal",
            boxShadow: "none",
            textDecoration: "none",
            p: 2,
            "&:hover": { backgroundColor: greyThree },
          },
        },
        {
          props: { variant: "pageHeader" },
          style: {
            padding: 0,
            boxShadow: "none",
            color: greySeven,
            fontSize: "0.75rem",
            fontWeight: "normal",
            "&:hover": {
              backgroundColor: "transparent",
            },
          },
        },
      ],
      styleOverrides: {
        root: {
          borderRadius: 8,
          textTransform: "none",
          boxShadow: mediumShadow,
          "& :focus": {
            outline: "3px solid ${alpha(theme.palette.primary.main, 0.5)}",
          },
        },
        contained: {
          color: "white",
          stroke: "white",
          ":disabled": {
            backgroundColor: greyTwo,
            stroke: black,
            color: black,
          },
        },
        text: {
          boxShadow: "none",
          color: black,
          fontSize: "0.75rem",
          fontWeight: "normal",
          "&:hover": {
            backgroundColor: "transparent",
          },
        },
        outlined: {
          margin: 0,
          borderColor: greyThree,
          backgroundColor: "white",
          color: black,
          fontWeight: "normal",
          "&:hover": {
            background: "white",
            borderColor: black,
          },
          ":disabled": {
            backgroundColor: greyTwo,
            stroke: black,
            color: black,
          },
        },
        textSizeSmall: {
          padding: 0,
          fontSize: "0.875rem",
          lineHeight: "20px",
        },
        textSizeMedium: {
          padding: 0,
          fontSize: "0.875rem",
          lineHeight: "24px",
        },
        textSizeLarge: {
          padding: 0,
          fontSize: "1.25rem",
          lineHeight: "28px",
        },
        sizeSmall: {
          paddingTop: SPACING * 2,
          paddingBottom: SPACING * 2,
          paddingLeft: SPACING * 3,
          paddingRight: SPACING * 3,
          fontSize: "0.875rem",
          lineHeight: "20px",
        },
        sizeMedium: {
          paddingTop: SPACING * 2,
          paddingBottom: SPACING * 2,
          paddingLeft: SPACING * 4,
          paddingRight: SPACING * 4,
          fontSize: "0.875rem",
          lineHeight: "24px",
        },
        sizeLarge: {
          paddingTop: SPACING * 3,
          paddingBottom: SPACING * 3,
          paddingLeft: SPACING * 4,
          paddingRight: SPACING * 4,
          fontSize: "1.25rem",
          lineHeight: "28px",
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          color: greySeven,
          padding: SPACING,
          margin: 0,
          "&:hover": { backgroundColor: greyThree },
        },
      },
    },
    MuiOutlinedInput: {
      defaultProps: {
        notched: false,
      },
      styleOverrides: {
        root: {
          borderRadius: 8,
        },
        input: {
          padding: 0,
        },
      },
    },
    MuiPagination: {
      defaultProps: {
        size: "small",
      },
      styleOverrides: {
        root: {
          display: "flex",
          justifyContent: "right",
          marginTop: SPACING * 6,
          "& button": {
            color: greyFive,
            "&:hover": {
              color: "white",
            },
          },
          "& buttonspan-selected": {
            color: purple,
            backgroundColor: greyTwo,
          },
          "& button.Mui-selected:hover": {
            color: "white",
            backgroundColor: purple,
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          "& fieldset": {
            borderColor: greyThree,
          },
          padding: 12,
          borderRadius: 2,
          backgroundColor: "white",
          fontSize: 16,
          transitionProperty: [
            "border-color",
            "background-color",
            "box-shadow",
          ],
          "&:focus-within": {
            boxShadow: (theme: Theme) =>
              `${alpha(theme.palette.grey[200], 1)} 0 0 0 0.2rem`,
            borderColor: (theme: Theme) => theme.palette.secondary.dark,
          },
        },
      },
    },
    MuiInputLabel: {
      defaultProps: {
        shrink: true,
      },
      variants: [
        {
          props: { variant: "dialog" as InputLabelProps["variant"] },
          style: {
            ...body1,
            color: greySix,
            fontWeight: "normal",
            marginTop: -SPACING,
          },
        },
      ],
      styleOverrides: {
        root: {
          color: black,
          fontSize: "0.875rem",
          fontWeight: 600,
          transform: "translate(0px, -24px)",
        },
      },
    },
    MuiTableContainer: {
      styleOverrides: {
        root: {
          width: "unset",
          marginLeft: -cardPaddingX,
          marginRight: -cardPaddingX,
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          textDecoration: "none",
          // do this so TableHead doesn't get hover style
          ".MuiTableRow-hover&:hover": {
            backgroundColor: "white",
            cursor: "pointer",
          },
          "&:last-child": {
            "& th, td": {
              border: "none", // removes double border from tablecontainer
            },
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          fontSize: 16,
          borderColor: greyTwo,
          "&:first-of-type": {
            paddingLeft: cardPaddingX,
          },
          "&:last-child": {
            paddingRight: cardPaddingX,
          },
        },
        head: {
          fontSize: 13,
          fontWeight: "normal",
          paddingTop: SPACING * 3,
          paddingBottom: SPACING * 3,
          color: greySix,
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          borderBottom: `1px solid ${greyTwo}`,
          textTransform: "uppercase",
        },
      },
    },
    MuiList: {
      defaultProps: {
        disablePadding: true,
      },
      styleOverrides: {
        root: {
          padding: 0,
        },
      },
    },
    MuiListItem: {
      defaultProps: {
        divider: true,
      },
      styleOverrides: {
        root: {
          "&:last-child": {
            border: "none",
            paddingBottom: 0,
          },
        },
        divider: {
          borderColor: greyTwo,
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          "&:hover": {
            backgroundColor: "transparent",
          },
          gap: SPACING,
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: 0,
        },
      },
    },
    MuiCard: {
      variants: [
        {
          props: { variant: "overview" as CardProps["variant"] },
          style: {
            display: "flex",
            flexDirection: "column",
          },
        },
      ],
      defaultProps: {
        elevation: 0,
      },
      styleOverrides: {
        root: {
          paddingLeft: cardPaddingX,
          paddingRight: cardPaddingX,
          borderRadius: SPACING * 2,
          border: 1,
          borderStyle: "solid",
          borderColor: greyTwo, // TODO:  change back to greyOne?
          boxShadow: lightShadow,
          [theme.breakpoints.down("sm")]: {
            borderRadius: 0,
            borderLeft: 0,
            borderRight: 0,
          },
        },
      },
    },
    MuiCardHeader: {
      styleOverrides: {
        root: {
          margin: `0px -${cardPaddingX}px`,
          padding: `${SPACING * 3}px ${cardPaddingX}px`,
          border: 0,
          borderBottom: 1,
          borderStyle: "solid",
          borderColor: greyTwo,
        },
        title: {
          fontSize: "1.35rem",
          lineHeight: "28px",
        },
        action: {
          margin: 0,
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          margin: 0,
          paddingLeft: 0,
          paddingRight: 0,
          paddingTop: SPACING * 4,
          paddingBottom: SPACING * 4,
          "&:last-child": {
            paddingBottom: SPACING * 4,
          },
        },
      },
    },
    MuiCardActions: {
      styleOverrides: {
        root: {
          padding: SPACING * 4,
          paddingLeft: 0,
          paddingRight: 0,
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        root: {
          "& .MuiDialogTitle-root": {
            "& .MuiIconButton-root": {
              padding: 0,
            },
          },
        },
        paperWidthMd: {
          maxWidth: 750, // Adjust dialog medium breakpoint
        },
        paper: {
          borderRadius: 12,
          paddingRight: SPACING * 6,
          paddingLeft: SPACING * 6,
          paddingTop: SPACING * 3,
          paddingBottom: SPACING * 3,
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        root: {
          // Adjust size of React Date Range Picker for mobile drawers
          "& .rdrCalendarWrapper, .rdrMonth": {
            width: "100%",
          },
        },
        paperAnchorBottom: {
          borderTopRightRadius: SPACING * 3,
          borderTopLeftRadius: SPACING * 3,
          paddingRight: SPACING * 4,
          paddingLeft: SPACING * 4,
          "& > div": {
            marginRight: -SPACING * 4,
            marginLeft: -SPACING * 4,
            paddingRight: SPACING * 4,
            paddingLeft: SPACING * 4,
          },
        },
      },
    },
    MuiSnackbarContent: {
      styleOverrides: {
        root: {
          borderRadius: 4,
          paddingTop: SPACING,
          paddingBottom: SPACING,
          paddingLeft: SPACING * 2,
          paddingRight: SPACING * 2,
          minWidth: "unset",
        },
        message: {
          padding: 0,
        },
      },
    },
    MuiSnackbar: {
      defaultProps: {
        anchorOrigin: { vertical: "bottom", horizontal: "center" },
      },
      styleOverrides: {
        root: {
          "& .MuiPaper-root": {
            minWidth: "unset",
          },
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          padding: 0,
          display: "flex",
          justifyContent: "space-between",
          alightItems: "center",
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          padding: SPACING * 6,
        },
        dividers: {
          marginLeft: -SPACING * 6, // needed to extend divider past Dialog paper padding
          marginRight: -SPACING * 6,
          marginTop: SPACING * 3,
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          paddingTop: SPACING * 3,
          paddingBottom: 0,
          paddingRight: 0,
          paddingLeft: 0,
          display: "flex",
          columnGap: SPACING * 4,
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          padding: 0,
          margin: 0,
          minHeight: "unset",
          "& .Mui-selected": {
            "& .MuiChip-label": {
              color: purple,
            },
          },
        },
        scrollableX: {
          "& a": {
            marginRight: SPACING * 2,
          },
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          color: greySix,
          paddingTop: 0,
          paddingBottom: SPACING * 2,
          paddingLeft: SPACING,
          paddingRight: SPACING,
          margin: 0,
          textTransform: "capitalize",
          minHeight: "unset",
          minWidth: "unset",
          marginRight: SPACING * 8,
          fontWeight: 400,
          [theme.breakpoints.down("sm")]: {
            marginRight: 0,
          },
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          height: "unset",
          color: greySeven,
          padding: `${SPACING * 0.5}px 0px`,
          fontSize: "0.875rem",
          "&.MuiChip-clickable": {
            "&:hover": {
              backgroundColor: greyThree,
            },
          },
        },
        filled: {
          backgroundColor: greyTwo,
        },
        outlined: {
          backgroundColor: "white",
        },
        clickable: {
          border: `1px solid ${greyThree}`,
        },
        label: {
          paddingRight: SPACING * 2,
          paddingLeft: SPACING * 2,
        },
        sizeSmall: {
          padding: 0,
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        popper: {
          background: purpleLight,
          color: purple,
        },
        option: {
          background: "white !important",
          "&:hover": {
            background: `${purpleLight} !important`,
            color: purple,
          },
        },
      },
    },
    MuiSelect: {
      defaultProps: {
        IconComponent: ExpandMoreIcon,
      },
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          border: `1px solid ${greyTwo}`,
          overflow: "visible",
          marginTop: 1.5,
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          margin: 0,
          paddingTop: SPACING * 2,
          paddingBottom: SPACING * 2,
          "&:hover": {
            color: purple,
            backgroundColor: purpleLighter,
            "& button": {
              color: purple,
            },
          },
          "& button": {
            textDecoration: "none",
            transition: "none",
          },
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          color: black,
        },
        underlineHover: {
          cursor: "pointer",
        },
      },
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          backgroundColor: greyThree,
          borderRadius: SPACING * 3,
          height: 5,
        },
        bar: {
          background: purple,
          borderRadius: SPACING * 3,
          height: 5,
        },
      },
    },
    MuiSlider: {
      styleOverrides: {
        rail: {
          backgroundColor: greyThree,
          borderRadius: SPACING * 3,
          height: 5,
        },
        track: {
          background: progressBarGradient,
          borderRadius: SPACING * 3,
          height: 5,
        },
      },
    },
    MuiBottomNavigation: {
      styleOverrides: {
        root: {
          backgroundColor: black,
        },
      },
    },
    MuiBottomNavigationAction: {
      styleOverrides: {
        root: {
          color: greySix,
          "&.Mui-selected": {
            color: "white",
            "& span": {
              fontSize: 12,
            },
          },
        },
      },
    },
    MuiTooltip: {
      defaultProps: {
        placement: "top",
      },
      styleOverrides: {
        tooltip: {
          fontWeight: 400,
          backgroundColor: black,
          paddingLeft: SPACING * 2,
          paddingRight: SPACING * 2,
          borderRadius: SPACING,
          maxWidth: 500,
          lineHeight: "1.2rem",
          fontSize: "0.875rem",
        },
      },
    },
    MuiSkeleton: {
      styleOverrides: {
        rectangular: {
          borderRadius: SPACING * 2,
          boxShadow: lightShadow,
          [theme.breakpoints.down("sm")]: {
            borderRadius: 0,
            borderLeft: 0,
            borderRight: 0,
          },
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          "& svg": {
            fontSize: 16,
          },
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: greySix,
        },
      },
    },
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          label: "p",
        },
      },
    },
  },
  palette: {
    background: {
      default: backGround,
    },
    action: {
      hover: purple,
      hoverOpacity: 0.4,
    },
    common: {
      black,
    },
    grey: {
      50: greyFifty,
      100: greyOne,
      200: greyTwo,
      300: greyThree,
      400: greyFour,
      500: greyFive,
      600: greySix,
      700: greySeven,
      800: greyEight,
      900: greyNine,
    },
    primary: {
      light: purpleLighter,
      300: purpleLight,
      main: purple,
      dark: purpleDark,
      900: purpleDarker,
    },
    secondary: {
      light: pinkLighter,
      main: pink,
    },
    error: {
      light: redLighter,
      300: redLight,
      main: red,
      dark: redDark,
      900: redDarker,
    },
    success: {
      light: greenLighter,
      300: greenLight,
      main: green,
      dark: greenDark,
      900: greenDarker,
    },
    warning: {
      light: yellowLighter,
      300: yellowLight,
      main: yellow,
      dark: yellowDark,
      900: yellowDarker,
    },
    info: {
      light: greyThree,
      main: greyFive,
      dark: greySeven,
    },
  },
  typography: {
    fontFamily: [
      "Nunito",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    fontWeightRegular: 400,
    fontWeightMedium: 600,
    fontWeightBold: 700,
    h1: {
      fontSize: "3rem",
      lineHeight: "60px",
    },
    h2: {
      fontSize: "2.25rem",
      lineHeight: "40px",
    },
    h3: {
      fontSize: "1.875rem",
      lineHeight: "36px",
    },
    h4: {
      fontSize: "1.5rem",
      lineHeight: "32px",
    },
    h5: {
      fontSize: "1.25rem",
      lineHeight: "28px",
    },
    h6: {
      fontSize: "1.125rem",
      lineHeight: "28px",
    },
    body1,
    body2,
    button: body1,
    caption: {
      fontSize: "0.75rem",
      lineHeight: "16px",
    },
    label: {
      ...body2,
      color: greySeven,
    },
  },
});

export default theme;
