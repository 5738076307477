import React from "react";
import { DateTime } from "luxon";
import {
  GridRowsProp,
  GridColDef,
  GridColumnGroupingModel,
  GridColumnVisibilityModel,
  gridStringOrNumberComparator,
} from "@mui/x-data-grid";
import {
  deriveComparisonDatesLabel,
  formatMediumDate,
  formatMonthDayWithoutTrailingZero,
  formatMonthDayYear,
  formatMonthDayYearWithWeekday,
} from "../dates";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { AnyVal, SpaceNodeByIdMap } from "../interfaces";
import { DateRangeListItem } from "./analytics-space-view";
import { ChartSeriesType } from "./datechart";
import { formatNumberWithComma } from "../util/format";
import { dataGridHeaderStyles, dwellBarColors } from "../theme";
import { DateRange } from "./analytics";

export interface DailyDwelltimeSeriesMetaDataItem {
  name: string; // name of the space
  seriesName: string; // name of the series (average or peak)
  color: string; // color of the series
  id: number; // id of the space
  type: ChartSeriesType; // average or peak
  series: AnyVal; // data for the series
}

const convertToNullIfNegative = (value: number | null) => {
  if (value === null || value < 0) return null;
  return value;
};

export const deriveDwellColor = (
  oldChartItem: DailyDwelltimeSeriesMetaDataItem | undefined,
  chartMetaData: DailyDwelltimeSeriesMetaDataItem[]
) => {
  if (oldChartItem) {
    return oldChartItem.color;
  }
  for (let i = 0; i < dwellBarColors.length; i++) {
    const color = dwellBarColors[i];
    const colorAlreadyUsed = chartMetaData.find((item) => item.color === color);
    if (!colorAlreadyUsed) {
      return color;
    }
  }
  return dwellBarColors[chartMetaData.length / 2];
};

export const initialDailyDwelltimeOptions: Highcharts.Options = {
  credits: {
    enabled: false,
  },
  chart: {
    type: "mixed",
    height: 300,
  },
  plotOptions: {
    column: {
      pointPadding: -0.1,
      opacity: 0.9,
    },
  },
  title: { text: undefined }, // hides the title
  yAxis: {
    title: { text: "Dwell Time (minutes)" }, // hides the title
    allowDecimals: false,
    labels: {
      formatter: function (this: AnyVal) {
        return formatNumberWithComma(this.value);
      },
    },
  },
  xAxis: {
    // caps tickAmount at 15
    tickPositioner: function () {
      if ((this as AnyVal).dataMax === 0) {
        return [0];
      }
      const positions = [];
      let tick = Math.floor((this as AnyVal).dataMin);
      const increment = Math.ceil(
        ((this as AnyVal).dataMax - (this as AnyVal).dataMin) / 15
      );

      if (
        (this as AnyVal).dataMax !== null &&
        (this as AnyVal).dataMin !== null
      ) {
        for (
          tick;
          tick - increment <= (this as AnyVal).dataMax;
          tick += increment
        ) {
          positions.push(tick);
        }
      }
      return positions;
    },
    type: "category",
    labels: {
      formatter: function () {
        const dateString = JSON.parse((this as AnyVal).value).date;
        const date = new Date(dateString);
        return formatMonthDayWithoutTrailingZero(date.toISOString());
      },
    },
  },
  exporting: {
    enabled: false,
  },
};

export const deriveDailyDwellChartDatestooltip = () => {
  return {
    tooltip: {
      formatter: function () {
        const parsedMetaData = JSON.parse((this as AnyVal).key);
        const date = new Date(parsedMetaData.date);
        const spaceName = parsedMetaData.spaceName;
        const formattedDate = formatMonthDayYearWithWeekday(date.toISOString());
        return `<b>${formattedDate}</b><br />${spaceName} Dwell Time (minutes): ${formatNumberWithComma(
          Math.round((this as AnyVal).y)
        )}`;
      },
    },
  };
};

export const deriveDailyDwellChartSpacestooltip: AnyVal = () => {
  return {
    tooltip: {
      formatter: function () {
        const parsedMetaData = JSON.parse((this as AnyVal).key);
        const date = new Date(parsedMetaData.date);
        const formattedDate = formatMonthDayYearWithWeekday(date.toISOString());
        const dwelltime = Math.round((this as AnyVal).y);
        const text = `${formatNumberWithComma(dwelltime)}`;
        return `<b>${formattedDate}</b><br />${
          (this as AnyVal).series.name
        } (minutes): ${text}`;
      },
    },
  };
};

export const deriveColumnPaddingOptions = (hasComparison: boolean) => {
  if (hasComparison) {
    return {
      plotOptions: {
        column: {
          pointPadding: 0,
        },
      },
    };
  }
  // Decreases the padding to make the columns wider
  return {
    plotOptions: {
      column: {
        pointPadding: -0.1,
      },
    },
  };
};

export const deriveComparisonColumnSeries: AnyVal = (
  data: AnyVal,
  seriesName: string,
  color: string,
  shouldDisplayAverage: boolean
) => {
  return {
    type: "column",
    name: seriesName,
    data,
    borderRadius: 4,
    color,
    showInLegend: false,
    visible: shouldDisplayAverage,
  };
};

export const deriveComparisonScatterSeries: AnyVal = (
  data: AnyVal,
  seriesName: string,
  color: string,
  shouldDisplayPeak: boolean
) => {
  return {
    type: "scatter",
    name: seriesName,
    data,
    color,
    marker: {
      symbol: "diamond",
      lineColor: "black",
      lineWidth: 1,
      radius: 3,
    },
    showInLegend: false,
    zIndex: 1, // so that the peak dots are on top of the bars
    visible: shouldDisplayPeak,
  };
};

const deriveScatterXValue = (
  i: number,
  barCount: number,
  indexOfBar: number
) => {
  // These offsets are used to position the scatter dots on top of the bars
  // when comparing multiple spaces or time ranges
  const offSet = 0.15;
  const offSet2 = 0.2;
  const offSet3 = 0.08;
  const offSet4 = 0.22;
  const offSet5 = 0.125;
  const offSet6 = 0.24;
  const offSet7 = 0.05;
  const offSet8 = 0.15;
  const offSet9 = 0.25;
  if (barCount === 1) {
    return i;
  }
  if (barCount === 2) {
    if (indexOfBar === 0) return i - offSet;
    if (indexOfBar === 1) return i + offSet;
  }
  if (barCount === 3) {
    if (indexOfBar === 0) return i - offSet2;
    if (indexOfBar === 1) return i;
    if (indexOfBar === 2) return i + offSet2;
  }
  if (barCount === 4) {
    if (indexOfBar === 0) return i - offSet4;
    if (indexOfBar === 1) return i - offSet3;
    if (indexOfBar === 2) return i + offSet3;
    if (indexOfBar === 3) return i + offSet4;
  }
  if (barCount === 5) {
    if (indexOfBar === 0) return i - offSet6;
    if (indexOfBar === 1) return i - offSet5;
    if (indexOfBar === 2) return i;
    if (indexOfBar === 3) return i + offSet5;
    if (indexOfBar === 4) return i + offSet6;
  }
  if (barCount === 6) {
    if (indexOfBar === 0) return i - offSet9;
    if (indexOfBar === 1) return i - offSet8;
    if (indexOfBar === 2) return i - offSet7;
    if (indexOfBar === 3) return i + offSet7;
    if (indexOfBar === 4) return i + offSet8;
    if (indexOfBar === 5) return i + offSet9;
  }

  return i;
};

const formatDateChartSpaceComparisonSeriesItem = (
  data: AnyVal,
  isSingleDayDateRange: boolean,
  capacity: number,
  dataMax: number,
  isScatter?: boolean,
  barCount?: number,
  indexOfBar?: number
) => {
  const newData = data.map((datum: AnyVal, i: number) => {
    if (datum[1] > dataMax) {
      dataMax = datum[1];
    }
    const x =
      isScatter && barCount && indexOfBar !== undefined && !isSingleDayDateRange
        ? deriveScatterXValue(i, barCount, indexOfBar)
        : i;
    return {
      x,
      y: convertToNullIfNegative(datum[1]),
      name: JSON.stringify({
        date: datum[0],
      }),
    };
  });
  return { newData, dataMax };
};

export const deriveDailyDwellChartSpacesMetaData = (
  parsedData: AnyVal[],
  nodes: SpaceNodeByIdMap,
  comparisonSpaceIds: string[],
  oldChartMetaData: DailyDwelltimeSeriesMetaDataItem[],
  shouldDisplayAverage: boolean,
  shouldDisplayPeak: boolean,
  dateRange: DateRange
) => {
  const dayData = parsedData[0];
  if (!dayData) {
    return { dailyDwellSeriesMetaData: [], xAxis: [], dataMax: 0 };
  }
  const dailyDwellSeriesMetaData: DailyDwelltimeSeriesMetaDataItem[] = [];
  let dataMax = 0;
  comparisonSpaceIds.forEach((id: string, indexOfBar: number) => {
    const chartData = dayData[id];
    const node = nodes[id];
    const capacity = node.capacity;
    const oldChartItem = oldChartMetaData.find((item) => item.id === node.id);
    const color = deriveDwellColor(oldChartItem, dailyDwellSeriesMetaData);

    const isSingleDayDateRange =
      DateTime.fromJSDate(dateRange.startDate).toISODate() ===
      DateTime.fromJSDate(dateRange.endDate).toISODate();

    if (!chartData) {
      return { dailyDwellSeriesMetaData: [], xAxis: [], dataMax: 0 };
    }

    const { newData: formattedAvg } = formatDateChartSpaceComparisonSeriesItem(
      chartData.Avg,
      isSingleDayDateRange,
      capacity,
      dataMax
    );
    const { newData: formattedPeak, dataMax: max3 } =
      formatDateChartSpaceComparisonSeriesItem(
        chartData.Peak,
        isSingleDayDateRange,
        capacity,
        dataMax,
        true,
        comparisonSpaceIds.length,
        indexOfBar
      );
    // gets peak max
    dataMax = max3;
    dailyDwellSeriesMetaData.push({
      name: node.name,
      seriesName: node.name + " Average Dwell Time",
      color,
      id: node.id,
      type: ChartSeriesType.Average,
      series: deriveComparisonColumnSeries(
        formattedAvg,
        node.name + " Average Dwell Time",
        color,
        shouldDisplayAverage
      ),
    });
    dailyDwellSeriesMetaData.push({
      name: node.name,
      seriesName: node.name + " Peak Dwell Time",
      color,
      id: node.id,
      type: ChartSeriesType.Peak,
      series: deriveComparisonScatterSeries(
        formattedPeak,
        node.name + " Peak Dwell Time",
        color,
        shouldDisplayPeak
      ),
    });
  });
  const xAxis: AnyVal[] = [];
  return { dailyDwellSeriesMetaData, xAxis, dataMax };
};

export const deriveDailyDwellChartDatesMetaData = (
  parsedData: AnyVal,
  nodes: SpaceNodeByIdMap,
  comparisonDateRanges: AnyVal[],
  dateRange: AnyVal,
  oldChartMetaData: DailyDwelltimeSeriesMetaDataItem[],
  shouldDisplayAverage: boolean,
  shouldDisplayPeak: boolean
) => {
  const dailyDwellSeriesMetaData: DailyDwelltimeSeriesMetaDataItem[] = [];

  const isSingleDayDateRange =
    DateTime.fromJSDate(dateRange.startDate).toISODate() ===
    DateTime.fromJSDate(dateRange.endDate).toISODate();

  const datesToCompare: DateRangeListItem[] = [
    { ...dateRange, indexOfDateRange: 0 },
    ...comparisonDateRanges,
  ];

  let dataMax = 0;

  const xAxis: string[] = [];
  datesToCompare.forEach(
    ({ startDate, indexOfDateRange, endDate }, indexOfBar) => {
      const comparisonLabel = deriveComparisonDatesLabel(startDate, endDate);
      const spaceData = parsedData[indexOfDateRange];
      const formatDateData = (
        data: AnyVal,
        spaceName: string,
        isScatter?: boolean,
        barCount?: number,
        indexOfBar?: number
      ) => {
        return data.map((datum: AnyVal, i: number) => {
          if (datum[1] > dataMax) {
            dataMax = datum[1];
          }
          const x =
            isScatter &&
            barCount &&
            indexOfBar !== undefined &&
            !isSingleDayDateRange
              ? deriveScatterXValue(i, barCount, indexOfBar)
              : i;
          const datumValue = datum[1] < 0 ? null : datum[1];
          return {
            x,
            y: datumValue,
            name: JSON.stringify({
              spaceName,
              date: datum[0],
            }),
          };
        });
      };
      const oldChartItem = oldChartMetaData.find(
        (item) => item.name === comparisonLabel
      );
      const color = deriveDwellColor(oldChartItem, dailyDwellSeriesMetaData);

      Object.keys(spaceData).forEach((id) => {
        const chartData = spaceData[id];
        const node = nodes[id];

        chartData.Avg.forEach((datum: AnyVal) => {
          xAxis.push(datum[0]);
        });

        dailyDwellSeriesMetaData.push({
          name: comparisonLabel,
          seriesName: comparisonLabel + " Average Dwell Time",
          color,
          id: node.id,
          type: ChartSeriesType.Average,
          series: deriveComparisonColumnSeries(
            formatDateData(chartData.Avg, node.name + " Average Dwell Time"),
            comparisonLabel + " Average Dwell Time",
            color,
            shouldDisplayAverage
          ),
        });
        dailyDwellSeriesMetaData.push({
          name: comparisonLabel,
          seriesName: comparisonLabel + " Peak Dwell Time",
          color,
          id: node.id,
          type: ChartSeriesType.Peak,
          series: deriveComparisonScatterSeries(
            formatDateData(
              chartData.Peak,
              node.name + " Peak Dwell Time",
              true,
              datesToCompare.length,
              indexOfBar
            ),
            comparisonLabel + " Peak Dwell Time",
            color,
            shouldDisplayPeak
          ),
        });
      });
    }
  );
  return { dailyDwellSeriesMetaData, xAxis, dataMax };
};

export const deriveDailyDwellChartXAxisOptions = (
  isDateComparison: boolean,
  xAxis: AnyVal[]
) => {
  if (isDateComparison) {
    return {
      xAxis: {
        labels: {
          formatter: function (value: AnyVal) {
            if (!xAxis[value.pos]) {
              return "";
            }
            const date = new Date(xAxis[value.pos]);
            return DateTime.fromISO(date.toISOString())
              .toUTC()
              .get("weekdayShort");
          },
        },
      },
    };
  }
  return {
    xAxis: {
      labels: {
        formatter: function () {
          const value = (this as AnyVal)?.chart?.options?.series[0].data[
            (this as AnyVal).pos
          ];
          if (!value) {
            // There is an odd case with larger date ranges that conflicts with how we are generating a custom number of ticks
            // The final tick in these cases are beyond the scope of the dates in the data, so we need to handle this case
            return "";
          }

          const dateString = JSON.parse(value?.name).date;
          const date = new Date(dateString);
          return formatMonthDayWithoutTrailingZero(date.toISOString());
        },
      },
    },
  };
};

export const deriveDailyDwellChartOptions = (
  parsedData: AnyVal,
  isDateComparison: boolean,
  shouldDisplayAverage: boolean,
  shouldDisplayPeak: boolean,
  options: Highcharts.Options,
  nodes: SpaceNodeByIdMap,
  comparisonSpaceIds: string[],
  comparisonDateRanges: AnyVal[],
  chartMetaData: DailyDwelltimeSeriesMetaDataItem[],
  dateRange: AnyVal
) => {
  if (!parsedData) {
    return { newOptions: options, dailyDwellSeriesMetaData: [], dataMax: 0 };
  }
  const { dailyDwellSeriesMetaData, xAxis, dataMax } = isDateComparison
    ? deriveDailyDwellChartDatesMetaData(
        parsedData,
        nodes,
        comparisonDateRanges,
        dateRange,
        chartMetaData,
        shouldDisplayAverage,
        shouldDisplayPeak
      )
    : deriveDailyDwellChartSpacesMetaData(
        parsedData,
        nodes,
        comparisonSpaceIds,
        chartMetaData,
        shouldDisplayAverage,
        shouldDisplayPeak,
        dateRange
      );
  const series = dailyDwellSeriesMetaData.map((item) => item.series);

  const deriveToolTip = () => {
    if (isDateComparison) {
      return deriveDailyDwellChartDatestooltip();
    }
    return deriveDailyDwellChartSpacestooltip();
  };

  const toolTip = deriveToolTip();

  const hasComparison = Boolean(
    comparisonDateRanges.length > 0 || comparisonSpaceIds.length > 1
  );

  const xAxisOptions = deriveDailyDwellChartXAxisOptions(
    isDateComparison,
    xAxis
  );

  const newOptions = {
    ...options,
    ...deriveColumnPaddingOptions(hasComparison),
    ...toolTip,
    ...xAxisOptions,
    series,
  };
  return { newOptions, dailyDwellSeriesMetaData, dataMax };
};

// =================================================================================================
// Table stuff
// =================================================================================================

const deriveDefaultColumnProps = (
  isMobileSized: boolean
): Partial<GridColDef> => {
  return {
    flex: 1,
    align: "left",
    headerAlign: "left",
    sortable: !isMobileSized, // Removes sort on mobile
    sortComparator: gridStringOrNumberComparator,
    renderCell: (params: AnyVal) => {
      const value =
        params.value === null
          ? "-"
          : formatNumberWithComma(Math.round(params.value));

      return (
        <Tooltip title={`${value} minutes`}>
          <Typography
            sx={{
              fontSize: "inherit",
              color: "inherit",
              fontWeight: "inherit",
            }}
          >
            {value}
          </Typography>
        </Tooltip>
      );
    },
  };
};

const dateColFormatter = (params: AnyVal, isMobileSized: boolean) => {
  return isMobileSized
    ? formatMonthDayYear(params.value as string)
    : DateTime.fromISO(params.value as string).toLocaleString(
        DateTime.DATE_MED
      );
};

const avgDwellText =
  "The average dwell time in minutes of this space for the indicated day and hour range defined above";
const peakDwellText =
  "The peak dwell time in minutes of this space for the indicated day and hour range defined above";

const HeaderWithTooltip = ({
  tooltip,
  headerName,
}: {
  tooltip: string;
  headerName: string;
}) => {
  return (
    <Tooltip title={tooltip}>
      <Typography sx={{ ...dataGridHeaderStyles }}>{headerName}</Typography>
    </Tooltip>
  );
};

const deriveDefaultDailyColumns = (isMobileSized: boolean): GridColDef[] => {
  const valueFormatter = (params: AnyVal) => {
    if (params.value === null) {
      return "-";
    }
    return formatNumberWithComma(Math.round(params.value));
  };
  return [
    {
      field: "col1",
      headerName: "Date",
      type: "date",
      ...deriveDefaultColumnProps(isMobileSized),
      renderCell: undefined,
      align: "left",
      headerAlign: "left",
      valueFormatter: (params) => dateColFormatter(params, isMobileSized),
      minWidth: 90,
    },
    {
      field: "average1",
      headerName: isMobileSized ? "Avg" : "Avg Dwell Time",
      type: "number",
      ...deriveDefaultColumnProps(isMobileSized),
      valueFormatter: valueFormatter,
      renderHeader(params) {
        return (
          <HeaderWithTooltip
            tooltip={avgDwellText}
            headerName={params.colDef.headerName || ""}
          />
        );
      },
    },
    {
      field: "peak1",
      headerName: isMobileSized ? "Peak" : "Peak Dwell Time",
      type: "number",
      ...deriveDefaultColumnProps(isMobileSized),
      valueFormatter: valueFormatter,
      renderHeader(params) {
        return (
          <Tooltip title={peakDwellText}>
            <Typography sx={{ ...dataGridHeaderStyles }}>
              {params.colDef.headerName}
            </Typography>
          </Tooltip>
        );
      },
    },
  ];
};

export const deriveDailyDwellTableVisibilityModel = (
  comparisonSpaceIds: string[],
  comparisonDateRanges: AnyVal[],
  tableShouldShowPeak: boolean,
  isMobileSized: boolean
): GridColumnVisibilityModel => {
  if (
    comparisonSpaceIds.length > 3 ||
    comparisonDateRanges.length > 2 ||
    (isMobileSized && comparisonSpaceIds.length > 1)
  ) {
    if (tableShouldShowPeak) {
      return {
        average1: false,
        average2: false,
        average3: false,
        average4: false,
        average5: false,
      };
    } else {
      return {
        peak1: false,
        peak2: false,
        peak3: false,
        peak4: false,
        peak5: false,
      };
    }
  }
  return {};
};

export const deriveDailyDwellTableSpacesComparisonData = (
  dateRange: AnyVal,
  availableDays: string[],
  comparisonDateRanges: AnyVal[],
  isDateComparison: boolean,
  tableShouldShowPeak: boolean,
  comparisonSpaceIds: string[],
  parsedData: AnyVal,
  nodes: SpaceNodeByIdMap,
  isMobileSized: boolean
): [
  rows: GridRowsProp,
  columns: GridColDef[],
  columnGroupingModel: GridColumnGroupingModel,
  columnVisibilityModel: GridColumnVisibilityModel
] => {
  if (!parsedData) {
    return [[], [], [], {}];
  }
  if (isDateComparison) {
    // TODO: clean this func up
    return deriveDailyDwellTableDatesComparisonData(
      comparisonSpaceIds[0],
      dateRange,
      availableDays,
      tableShouldShowPeak,
      comparisonDateRanges,
      parsedData,
      nodes,
      isMobileSized
    );
  }
  const dailyData = parsedData[0];
  if (!dailyData) {
    return [[], [], [], {}];
  }

  const deriveSpacesColumns = (): GridColDef[] => {
    if (comparisonSpaceIds.length === 1) {
      return deriveDefaultDailyColumns(isMobileSized);
    }
    const columns: GridColDef[] = [];

    const valueFormatter = () => {
      return {
        valueFormatter: ({ value }: { value: AnyVal }) => {
          if (value === null) {
            return "-";
          }
          return formatNumberWithComma(Math.round(value));
        },
      };
    };
    // Initial columns
    columns.push({
      field: "col1",
      headerName: "Date",
      type: "date",
      valueFormatter: (params) => dateColFormatter(params, isMobileSized),
      ...deriveDefaultColumnProps(isMobileSized),
      renderCell: undefined,
      align: "left",
      headerAlign: "left",
      renderHeader: undefined,
    });
    // Gather and push all average columns
    comparisonSpaceIds.forEach((id: string, i: number) => {
      columns.push({
        field: `average${i + 1}`,
        headerName: nodes[id].name,
        type: "number",
        ...valueFormatter(),
        ...deriveDefaultColumnProps(isMobileSized),
        renderHeader: undefined,
      });
    });
    // Gather and push all peak columns
    comparisonSpaceIds.forEach((id: string, i: number) => {
      columns.push({
        field: `peak${i + 1}`,
        headerName: nodes[id].name,
        type: "number",
        ...valueFormatter(),
        ...deriveDefaultColumnProps(isMobileSized),
        renderHeader: undefined,
      });
    });
    return columns;
  };

  const deriveColumnGroupingModel = (): GridColumnGroupingModel => {
    if (comparisonSpaceIds.length === 1) {
      return [];
    }
    const columnGroupingModel: GridColumnGroupingModel = [];
    const averageColumns: GridColDef[] = [];
    const peakColumns: GridColDef[] = [];
    comparisonSpaceIds.forEach((id: string, i: number) => {
      averageColumns.push({ field: `average${i + 1}` });
      peakColumns.push({ field: `peak${i + 1}` });
    });
    columnGroupingModel.push(
      {
        groupId: "Average Dwell",
        children: averageColumns,
        headerAlign: "center",
        renderHeaderGroup(params) {
          return (
            <HeaderWithTooltip
              tooltip={avgDwellText}
              headerName={params.headerName || ""}
            />
          );
        },
      },
      {
        groupId: "Peak Dwell",
        children: peakColumns,
        headerAlign: "center",
        renderHeaderGroup(params) {
          return (
            <HeaderWithTooltip
              tooltip={peakDwellText}
              headerName={params.headerName || ""}
            />
          );
        },
      }
    );
    return columnGroupingModel;
  };

  const deriveRows = (): GridRowsProp => {
    const spaceDataOne = dailyData[comparisonSpaceIds[0]];
    if (!spaceDataOne) {
      return [];
    }
    if (comparisonSpaceIds.length === 1) {
      const rows: AnyVal = [];
      spaceDataOne.Avg.forEach((item: AnyVal, i: number) => {
        const hasDate = availableDays.includes(item[0]);
        if (hasDate) {
          rows.push({
            id: rows.length + 1,
            col1: item[0],
            average1: convertToNullIfNegative(item[1]),
            peak1: convertToNullIfNegative(spaceDataOne.Peak[i][1]),
          });
        }
      });
      return rows;
    } else {
      const rows: AnyVal = [];
      spaceDataOne.Avg.forEach((item: AnyVal, i: number) => {
        const hasDate = availableDays.includes(item[0]);
        const row: AnyVal = {
          id: rows.length + 1,
          col1: item[0],
        };
        if (hasDate) {
          // gather average rows
          comparisonSpaceIds.forEach((id: string, index: number) => {
            const spaceData = dailyData[id];
            row[`average${index + 1}`] = convertToNullIfNegative(
              spaceData.Avg[i][1]
            );
          });
          // gather peak rows
          comparisonSpaceIds.forEach((id: string, index: number) => {
            const spaceData = dailyData[id];
            row[`peak${index + 1}`] = convertToNullIfNegative(
              spaceData.Peak[i][1]
            );
          });
          rows.push(row);
        }
      });
      return rows;
    }
  };

  const columns = deriveSpacesColumns();
  const columnGroupingModel = deriveColumnGroupingModel();
  const rows = deriveRows();
  const columnVisibilityModel = deriveDailyDwellTableVisibilityModel(
    comparisonSpaceIds,
    comparisonDateRanges,
    tableShouldShowPeak,
    isMobileSized
  );
  return [rows, columns, columnGroupingModel, columnVisibilityModel];
};

const deriveDateTableToolTipAndValue = (
  parsedMetaData: AnyVal,
  isMobileSized: boolean
) => {
  const date = formatMediumDate(parsedMetaData.date as string);
  const type = parsedMetaData.type;
  if (parsedMetaData.value === null) {
    return {
      tooltip: `${date} ${type} Dwell: -`,
      tableValue: "-",
    };
  }
  const valueString = isMobileSized
    ? formatNumberWithComma(Math.round(parsedMetaData.value))
    : `${formatNumberWithComma(Math.round(parsedMetaData.value))}`;
  return {
    tooltip: `${date} ${type} Dwell (minutes): ${
      parsedMetaData.value ? valueString : "-"
    }`,
    tableValue: valueString,
  };
};

const deriveDateComparisonTableColumnProps = (isMobileSized: boolean) => {
  return {
    sortComparator: (v1: string, v2: string) => {
      const parsedV1 = JSON.parse(v1);
      const parsedV2 = JSON.parse(v2);
      return parsedV1.value - parsedV2.value;
    },
    renderCell: (params: AnyVal) => {
      const parsedMetaData = JSON.parse(params.value as string);
      const { tooltip, tableValue } = deriveDateTableToolTipAndValue(
        parsedMetaData,
        isMobileSized
      );
      return (
        <Tooltip title={tooltip}>
          <Typography
            sx={{
              fontSize: "inherit",
              color: "inherit",
              fontWeight: "inherit",
            }}
          >
            {tableValue}
          </Typography>
        </Tooltip>
      );
    },
  };
};

export const deriveDailyDwellTableDatesComparisonData = (
  spaceId: string,
  dateRange: AnyVal,
  availableDays: string[],
  tableShouldShowPeak: boolean,
  comparisonDateRanges: AnyVal[],
  parsedData: AnyVal,
  nodes: SpaceNodeByIdMap,
  isMobileSized: boolean
): [
  rows: GridRowsProp,
  columns: GridColDef[],
  columnGroupingModel: GridColumnGroupingModel,
  columnVisibilityModel: GridColumnVisibilityModel
] => {
  const datesToCompare = [dateRange, ...comparisonDateRanges];
  const dailyData = parsedData;

  const deriveDatesColumns = (): GridColDef[] => {
    const columns: GridColDef[] = [];
    // Initial columns
    columns.push({
      field: "col1",
      headerName: "Date",
      type: "date",
      ...deriveDefaultColumnProps(isMobileSized),
      renderCell: undefined,
      valueFormatter: (params) => dateColFormatter(params, isMobileSized),
      renderHeader: undefined,
    });
    // Gather and push all average columns
    datesToCompare.forEach((dateRange: DateRangeListItem, i: number) => {
      columns.push({
        field: `average${i + 1}`,
        headerName: deriveComparisonDatesLabel(
          dateRange.startDate,
          dateRange.endDate
        ),
        type: "number",
        valueFormatter: undefined,
        ...deriveDefaultColumnProps(isMobileSized),
        renderHeader: undefined,
        ...deriveDateComparisonTableColumnProps(isMobileSized),
      });
    });
    // Gather and push all peak columns
    datesToCompare.forEach((dateRange: DateRangeListItem, i: number) => {
      columns.push({
        field: `peak${i + 1}`,
        headerName: deriveComparisonDatesLabel(
          dateRange.startDate,
          dateRange.endDate
        ),
        type: "number",
        valueFormatter: undefined,
        ...deriveDefaultColumnProps(isMobileSized),
        renderHeader: undefined,
        ...deriveDateComparisonTableColumnProps(isMobileSized),
      });
    });
    return columns;
  };

  // TODO: make common for spaces and dates?
  const deriveColumnGroupingModel = (): GridColumnGroupingModel => {
    const columnGroupingModel: GridColumnGroupingModel = [];
    const averageColumns: GridColDef[] = [];
    const peakColumns: GridColDef[] = [];
    datesToCompare.forEach((dateRange: DateRangeListItem, i: number) => {
      averageColumns.push({ field: `average${i + 1}` });
      peakColumns.push({ field: `peak${i + 1}` });
    });
    columnGroupingModel.push(
      {
        groupId: "Average Dwell",
        children: averageColumns,
        headerAlign: "center",
        renderHeaderGroup(params) {
          return (
            <HeaderWithTooltip
              tooltip={avgDwellText}
              headerName={params.headerName || ""}
            />
          );
        },
      },
      {
        groupId: "Peak Dwell",
        children: peakColumns,
        headerAlign: "center",
        renderHeaderGroup(params) {
          return (
            <HeaderWithTooltip
              tooltip={peakDwellText}
              headerName={params.headerName || ""}
            />
          );
        },
      }
    );
    return columnGroupingModel;
  };

  const deriveRows = (): GridRowsProp => {
    const dateOne = dailyData[0][spaceId];
    const rows: AnyVal = [];
    dateOne.Avg.forEach((item: AnyVal, i: number) => {
      const hasDate = availableDays.includes(item[0]);
      const row: AnyVal = {
        id: rows.length + 1,
        col1: item[0],
      };
      if (hasDate) {
        // gather average rows
        datesToCompare.forEach(
          (dateRange: DateRangeListItem, index: number) => {
            const dateData = dailyData[index][spaceId];
            row[`average${index + 1}`] = JSON.stringify({
              date: dateData.Avg[i][0],
              value: convertToNullIfNegative(dateData.Avg[i][1]),
              type: ChartSeriesType.Average,
            });
          }
        );
        // gather peak rows
        datesToCompare.forEach(
          (dateRange: DateRangeListItem, index: number) => {
            const dateData = dailyData[index][spaceId];
            row[`peak${index + 1}`] = JSON.stringify({
              date: dateData.Peak[i][0],
              value: convertToNullIfNegative(dateData.Peak[i][1]),
              type: ChartSeriesType.Peak,
            });
          }
        );
        rows.push(row);
      }
    });
    return rows;
  };

  const columns = deriveDatesColumns();
  const columnGroupingModel = deriveColumnGroupingModel();
  const rows = deriveRows();
  const columnVisibilityModel = deriveDailyDwellTableVisibilityModel(
    [spaceId],
    comparisonDateRanges,
    tableShouldShowPeak,
    isMobileSized
  );
  return [rows, columns, columnGroupingModel, columnVisibilityModel];
};
